import React from 'react'

const HotItWorks = (props) => {
  return (
    <>
      <h2 className="text-center">Features</h2>
      <div className="container mt-3">
        <div className="row row-cols-3">
          <div className="col mb-2">
            <div className="h-100 p-3 d-flex flex-column align-items-center">
              {/* <svg
                className="rounded-pill mb-4"
                width="200"
                height="200"
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="xMidYMid slice"
                focusable="false"
                role="img"
                aria-label="Placeholder: 200x200"
              >
                <rect width="100%" height="100%" fill="#fff"></rect>
              </svg> */}
              <p className="text-center">Play using any browser (desktop and mobile)</p>
            </div>
          </div>
          <div className="col mb-2">
            <div className="h-100 p-3 d-flex flex-column align-items-center">
              {/* <svg
                className="rounded-pill mb-4"
                width="200"
                height="200"
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="xMidYMid slice"
                focusable="false"
                role="img"
                aria-label="Placeholder: 200x200"
              >
                <rect width="100%" height="100%" fill="#fff"></rect>
              </svg> */}

              <p className="text-center">Play with your friends online</p>
            </div>
          </div>
          <div className="col mb-2">
            <div className="h-100 p-3 d-flex flex-column align-items-center">
              {/* <svg
                className="rounded-pill mb-4"
                width="200"
                height="200"
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="xMidYMid slice"
                focusable="false"
                role="img"
                aria-label="Placeholder: 200x200"
              >
                <rect width="100%" height="100%" fill="#fff"></rect>
              </svg> */}

              <p className="text-center">Make your own escape game (soon)</p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default HotItWorks
