import React from 'react'

const GAMES = [
  {
    name: 'RGB',
    id: '1lOEWJRE3s1WO5Sn4-2y9nx2FICGa1qwN',
    dificulty: 2,
    duration: 20,
    language: 'en-US',
  },
  {
    name: 'Mage Room',
    id: '1YmsdvGRTiq027iPBZ95_hWILibda5NuN',
    dificulty: 3,
    duration: 30,
    language: 'pt-BR',
  },
  {
    name: '1 ♥️ U',
    id: '1gbap6cHV8xv-wpsGI_79yQR9lroDlyBg',
    dificulty: 5,
    duration: 30,
    language: 'pt-BR',
  },
]

const GameList = (props) => {
  return (
    <>
      <div className="row row-cols-2 row-cols-md-3 align-items-center">
        {GAMES.map((game) => (
          <div className="col mb-4" key={game.id}>
            <div className="card bg-light text-dark shadow-sm h-100 text-center">
              <div className="card-body">
                <h5 className="card-title">{game.name}</h5>
                <p className="card-text">
                  <span>Dificulty:</span> <strong>{game.dificulty}</strong>
                  <br />
                  <span>Estimated time:</span> <strong>{game.duration} min</strong>
                  <br />
                  <span>Language:</span> <strong>{game.language}</strong>
                  <br />
                </p>
                <a href={`https://play.escapegamemaker.com#g=${game.id}`} className="btn btn-primary">
                  Play now!
                </a>
              </div>
            </div>
          </div>
        ))}

        <div className="col mb-4">
          <p className="text-muted text-center">More soon!</p>
        </div>
      </div>
    </>
  )
}

export default GameList
