import React from 'react'
import { Helmet } from 'react-helmet'
import '../sass/index.scss'
import GameList from '../components/GameList'
import HowItWorks from '../components/HowItWorks'

const Homepage = (props) => {
  return (
    <>
      <Helmet>
        <title>Escape Game Maker</title>
        <link href="https://fonts.googleapis.com/css2?family=Jost:wght@500;700&display=swap" rel="stylesheet" />
        <script src="https://kit.fontawesome.com/d9cd288013.js" crossOrigin="anonymous"></script>
      </Helmet>
      {/* <nav className="navbar navbar-dark position-absolute">
        <span className="navbar-brand mb-0 h1">Escape Game Maker</span>
      </nav> */}
      <div className="py-5 text-center jumbo">
        <img src="/images/egmlogo.svg" className="my-3"/>
      </div>
      <section className="bg-primary text-light py-3">
        <HowItWorks />
      </section>{' '}
      <section className="py-3">
        <h2 className="text-center text-secondary">Play one of our digital escape games</h2>

        <div className="container mt-5">
          <GameList />
        </div>
      </section>
      <footer className="bg-dark text-muted py-3">
        <div className="container">
          <ul className="list-unstyled">
            <li><i className="fab fa-twitter mr-2"/>@escapegamemaker</li>
            <li><a href="/termsofuse.md">Terms of use</a></li>
            <li><a href="/privacy.md">Privacy policy</a></li>
          </ul>
        </div>
      </footer>
    </>
  )
}

export default Homepage
